<!-- Fab Button -->
<!-- <div class="fixed inset-0 z-50 overflow-y-auto flex items-start justify-end" (click)="displayFabLinks =! displayFabLinks"> -->
  <div class="relative" id="fabButton">
    <button aria-label="fab-button"
      class="fixed bottom-16 shadow-2xl shadow-white right-4 p-4 bg-amber-500 text-white rounded-full"
      (click)="displayFabLinks =! displayFabLinks">
  
      <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        *ngIf="!displayFabLinks">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
      </svg>
      <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        *ngIf="displayFabLinks">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
    <!-- <div class="fixed inset-0 z-50 overflow-y-auto flex items-start justify-end" (click)="displayFabLinks =! displayFabLinks"> -->
    <div class="fixed bg-white p-2 rounded shadow-lg right-16 bottom-24 border border-dark" *ngIf="displayFabLinks" (click)="stopDisplayFabLinksModal($event)">
      <div class="grid grid-cols-2" >
        <a href="tel:+918282924242"
          class="block cursor-pointer px-4 py-3 text-gray-800 hover:bg-gray-200 font-mono leading-tight hover:rounded text-center"><img
            height="40px" width="40px" class="m-auto mb-1" src="assets/images/phone.png" alt="phone-call" />Help</a>
        <a href="https://wa.me/+918282924242?text=Hi, I Need a help from Foot2Feet" target="_blank"
          class=" block cursor-pointer px-4 py-3 text-gray-800 hover:bg-gray-200 font-mono leading-tight hover:rounded text-center"><img
            height="40px" width="40px" class="m-auto mb-1" src="assets/images/whatsapp.png" alt="Whatsapp" />
          Whatsapp</a>
        <a [routerLink]="['/questions']" (click)="displayFabLinks = !displayFabLinks"
          routerLinkActive="router-link-active"
          class="block cursor-pointer px-4 py-3 text-gray-800 hover:bg-gray-200 font-mono leading-tight hover:rounded text-center"><img
            height="40px" width="40px" class="m-auto mb-1" src="assets/images/request.png" alt="phone-call" />Add a <br>
          Question</a>
        <a (click)="appC.openServiceRequestModal('');displayFabLinks = !displayFabLinks"
          class="block cursor-pointer px-4 py-3 text-gray-800 hover:bg-gray-200 font-mono leading-tight hover:rounded text-center"><img
            height="40px" width="40px" class="m-auto mb-1" src="assets/images/customer-service.png" alt="phone-call" />
          Add a <br> Service</a>
      </div>
    </div>
  </div>
<!-- </div> -->

  