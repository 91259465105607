import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';


@Component({
  selector: 'app-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss']
})
export class FabButtonComponent {

  displayFabLinks = false;

  constructor(public appC: AppComponent){}

  stopDisplayFabLinksModal(event: MouseEvent){
    event.stopPropagation();
  }
}
